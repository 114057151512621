import { lazy } from "react";

import Home from "./Pages/Home/Home";

const Activate = lazy(() => import("./Pages/Activate/Activate"));
const AllLists = lazy(() => import("Pages/AllLists/AllLists"));
const BlogPost = lazy(() => import("Pages/BlogPost/BlogPost"));
const ChangePassword = lazy(() =>
  import("Components/ChangePassword/ChangePassword")
);
const Configure = lazy(() => import("Components/Configure/Configure"));
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const DeleteUserAccount = lazy(() =>
  import("Components/DeleteUserAccount/DeleteUserAccount")
);
const DeletedHistory = lazy(() =>
  import("Pages/DeletedHistory/DeletedHistory")
);
const EditProfile = lazy(() => import("Pages/EditProfile/EditProfile"));
const EditUserDetails = lazy(() =>
  import("Components/EditUserDetails/EditUserDetails")
);
const Login = lazy(() => import("./Pages/Login/Login"));
const MyNetwork = lazy(() => import("Pages/MyNetwork/MyNetwork"));
const NewMyListsPage = lazy(() =>
  import("Components/NewDashboard/NewDashboard")
);
const NewUserProfile = lazy(() =>
  import("Components/NewUserProfile/NewUserProfile")
);
const OtherLists = lazy(() => import("./Pages/OtherLists/OtherLists"));
const OthersNetwork = lazy(() => import("Pages/Networks/OthersNetwork"));
const Register = lazy(() => import("./Pages/Register/Register"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword/ResetPassword"));
const SearchResults = lazy(() => import("./Pages/SearchResults/SearchResults"));
const SharePost = lazy(() => import("Pages/SharePost/SharePost"));
const StaticPageRender = lazy(() => import("./Pages/StaticPages/StaticPages"));
const ViewList = lazy(() => import("./Pages/ViewList/ViewList"));
const ConformConnection = lazy(() =>
  import("Pages/ConfirmConnection/ConfirmConnection")
);
const SubscribeActivate = lazy(() =>
  import("Pages/SubscribeActivate/SubscribeActivate")
);
const Blogs = lazy(() => import("Pages/Blogs/Blogs"));
const AcceptShare = lazy(() => import("Pages/AcceptShare/AcceptShare"));
const UnsubscribeWeeklyUpdates = lazy(() =>
  import("Pages/UnsubscribeWeeklyUpdates/UnsubscribeWeeklyUpdates")
);
const UnsubscribeNewsletter = lazy(() =>
  import("Pages/UnsubscribeNewsletter/UnsubscribeNewsletter")
);
const BusinessProfile = lazy(() =>
  import("Pages/BusinessProfile/BusinessProfile")
);
const BlogsXML = lazy(() => import("Pages/BlogsXML/BlogsXML"));
const PostsXML = lazy(() => import("Pages/PostsXML/PostsXML"));
const SitemapXML = lazy(() => import("Pages/SitemapXML/SitemapXML"));
const ManageSubscriptions = lazy(() =>
  import("Components/ManageSubscriptions/ManageSubscriptions")
);

// import Profile from "./Pages/Profile/Profile";

export const protectedRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    sidebar: true,
  },
  {
    path: "/lists",
    name: "Lists",
    component: <NewMyListsPage />,
    sidebar: true,
    subRoutes: [
      {
        path: ":username",
        component: <OtherLists />,
        name: "User List",
      },
    ],
  },
  {
    path: "/all-lists",
    name: "All Lists",
    component: <AllLists />,
    sidebar: true,
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    component: <EditProfile />,
    sidebar: false,
    subRoutes: [
      {
        path: "change-password",
        component: <ChangePassword />,
        name: "Change Password",
      },
      {
        path: "user-details",
        component: <EditUserDetails />,
        name: "User Details",
      },
      {
        path: "notifications",
        component: <Configure />,
        name: "Notifications",
      },
      {
        path: "manage-subscriptions",
        component: <ManageSubscriptions />,
        name: "Manage Subscriptions",
      },
      {
        path: "delete-account",
        component: <DeleteUserAccount />,
        name: "Delete Account",
      },
    ],
  },
  {
    path: "/search-results",
    name: "Search",
    component: <SearchResults />,
    sidebar: true,
  },
  {
    path: "/user-profile",
    name: "Profile",
    component: <NewUserProfile />,
    sidebar: true,
  },
  {
    path: "/my-network",
    name: "My Network",
    component: <MyNetwork />,
    sidebar: true,
    subRoutes: [
      {
        path: ":username",
        component: <OthersNetwork />,
        name: "Others Network",
      },
    ],
  },
  {
    path: "/deleted-history",
    name: "Deleted History",
    component: <DeletedHistory />,
    sidebar: true,
  },
  {
    path: "/view-list/:subjectHref/:listID",
    name: "View List",
    component: <ViewList />,
    sidebar: false,
  },
  {
    path: "/confirm-connection/:userID",
    name: "Confirm Connection",
    component: <ConformConnection />,
    sidebar: false,
  },
  {
    path: "/accept-share/:listID",
    name: "Accept Share",
    component: <AcceptShare />,
    sidebar: false,
  },
];

export const onlyLoggedOutRoutes = [
  {
    path: "/",
    name: "Home",
    component: <Home />,
  },
  {
    path: "/register",
    name: "Register",
    component: <Register />,
  },
  {
    path: "/activate/:userID",
    name: "Activate",
    component: <Activate />,
    sidebar: false,
  },
  {
    path: "/reset-password/:userID",
    name: "Reset Password",
    component: <ResetPassword />,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: <Login />,
  },
  {
    path: "/subscribe/:ID",
    name: "Subscribe",
    component: <SubscribeActivate />,
  },
];

export const publicRoutes = [
  {
    path: "/contact-us",
    name: "Contact Us",
    component: <ContactUs />,
    sidebar: false,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: <Blogs />,
    sidebar: true,
  },
  {
    path: "/posts/:postId",
    name: "Posts",
    component: <SharePost />,
    sidebar: false,
  },
  {
    path: "/blog/:blogID",
    name: "Blog Post",
    component: <BlogPost />,
    sidebar: false,
  },
  {
    path: "/:pageHref",
    name: "Demo Subject",
    component: <StaticPageRender />,
    sidebar: false,
  },
  {
    path: "/unsubscribe-weekly-updates/:ID",
    name: "Unsubscribe Weekly updates",
    component: <UnsubscribeWeeklyUpdates />,
    sidebar: false,
  },
  {
    path: "/unsubscribe-newsletter/:ID",
    name: "Unsubscribe Newsletter",
    component: <UnsubscribeNewsletter />,
    sidebar: false,
  },
  {
    path: "/business-profile",
    name: "Business Profile",
    component: <BusinessProfile />,
    sidebar: false,
  },
  {
    path: "/blogs-sitemap.xml",
    name: "Blogs XML",
    component: <BlogsXML />,
    sidebar: false,
  },
  {
    path: "/posts-sitemap.xml",
    name: "Posts XML",
    component: <PostsXML />,
    sidebar: false,
  },
  {
    path: "/sitemap.xml",
    name: "Sitemap XML",
    component: <SitemapXML />,
    sidebar: false,
  },
];
