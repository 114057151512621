import "./home.styles.scss";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { LogoSVG } from "assets/index.assets";
import { useEffect, useState } from "react";
// import { extractStringFromHTML } from "Utils/helper-functions";

const HomeBlogCards = ({ blog }) => {
  // state
  const [description, setDescription] = useState("");

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (blog) {
      // const _description = extractStringFromHTML(blog?.content)
      //   .slice(0, 350)
      //   .replace(blog.title, "");

      setDescription(blog.description || "");
    }
  }, [blog]);

  const handleViewBlog = () => {
    navigate(`/blog/${blog.href}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="home-blog-cards">
      {blog?.photo?.url ? (
        <img
          src={blog?.photo?.url}
          alt={blog?.title || "blog-image"}
          className="blog-pic"
          onClick={handleViewBlog}
          loading="lazy"
        />
      ) : (
        <img
          src={LogoSVG}
          alt={blog?.title || "blog-image"}
          className="blog-pic missing-image"
          onClick={handleViewBlog}
          loading="lazy"
        />
      )}
      <Box className="blog-details">
        <span className="title" onClick={handleViewBlog}>
          {blog?.title}
        </span>
        <div>
          <p className="description">{description}</p>
        </div>
        <span className="posted-date">
          {dayjs(blog?.lastUpdated).fromNow()}
        </span>
      </Box>
    </div>
  );
};

export default HomeBlogCards;
