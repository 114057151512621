import "./section.styles.scss";

import ReactPlayer from "react-player";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";

const SIT_BACK =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/sit_back.svg";
const RELAX =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/relax.svg";
const REMEMBER =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/remember.svg";
const SOCIAL_VIDEO = "https://youtu.be/B0EaiV2u5Qw";

const Section7 = () => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Box className="section-7">
      <div className="top-polygon" />
      <Box sx={{ mt: "-8rem", mb: "-5.5rem" }}>
        <Container maxWidth="xl">
          <Grid
            container
            columnSpacing={{
              xs: 0,
              md: "4rem",
              // lg: "8rem",
              // xl: "5rem",
            }}
            rowSpacing={9}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                  height: "100%",
                  paddingLeft: {
                    xs: "2rem",
                    md: "7rem",
                  },
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    color: "#333",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1.5rem",
                      md: "2.8rem",
                    },
                  }}
                >
                  Features
                </Typography>
                <p style={{ fontSize: "1.2rem" }}>
                  Are you a student, teacher, headteacher, CEO or a professional
                  in business with an educational story, experience, or
                  knowledge that could inspire others? If you are, then we would
                  be delighted to share this through our blog, a podcast, or an
                  interview. It is more than likely that your story will be an
                  example for others to follow, or the catalyst for change.
                </p>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1.8rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      src={SIT_BACK}
                      alt="sit-back"
                      width="60px"
                      height="60px"
                      loading="lazy"
                    />
                    <h3 style={{ fontWeight: 600 }}>Sit Back</h3>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      src={RELAX}
                      alt="Relax"
                      width="60px"
                      height="60px"
                      loading="lazy"
                    />
                    <h3 style={{ fontWeight: 600 }}>Relax</h3>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      src={REMEMBER}
                      alt="Remember"
                      width="60px"
                      height="60px"
                      loading="lazy"
                    />
                    <h3 style={{ fontWeight: 600 }}>& Remember</h3>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                  },
                  alignItems: "center",
                }}
              >
                <Box className="large-circle" />
                <Box
                  className="center"
                  onMouseEnter={() => setIsPlaying(true)}
                  onMouseLeave={() => setIsPlaying(false)}
                  sx={{
                    flexGrow: 1,
                    height: "340px",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "8px",
                    mr: {
                      xs: 0,
                      sm: "4rem",
                    },
                    maxWidth: {
                      xs: "100%",
                      // sm: "600px",
                      // md: "500px",
                      // lg: "600px",
                    },
                  }}
                >
                  <ReactPlayer
                    style={{
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                    width="100%"
                    height="100%"
                    controls={true}
                    url={SOCIAL_VIDEO}
                    // playing={isPlaying}
                    playing
                    light
                    muted
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <div className="bottom-polygon" />
    </Box>
  );
};

export default Section7;
