import "./styles.scss";

import { Box } from "@mui/material";
import { MetaTags } from "react-meta-tags";
import Section1 from "./Section1";
import Section2 from "./Section2";
import SubjectSection3 from "./SubjectSection3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";

// import Logo from "assets/images/logo1.svg";

// const Carousel_Images = [
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/image1.jpg",
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/image2.jpg",
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/image3.jpg",
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/image4.jpg",
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/image5.jpg",
// ];

// const Logo =
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/logo1.svg";

// videos
// const Video1 =
//   "https://kewordal-images.s3.eu-west-1.amazonaws.com/video/kewordal-marketing-video-2-s.mp4";
// const Video2 =
//   "https://kewordal-images.s3.eu-west-1.amazonaws.com/video/kewordal-marketing-video-1-s.mp4";
// const Video2 = "https://youtu.be/_sP55WlEaCg";

const Home = () => {
  return (
    <Box className="home">
      <MetaTags>
        <title>
          Kewordal: The Fun & Engaging Learning Platform for Students
        </title>
        <meta
          name="description"
          content="Kewordal is a fun and engaging online learning platform that assists students in mastering new concepts through interactive games, quizzes, and lessons."
        />
      </MetaTags>
      <div>
        <Section1 />
      </div>
      <Section2 />
      <SubjectSection3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </Box>
  );
};

export default Home;
