import "./section.styles.scss";

import { Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const JOIN =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/join.svg";
const BUILD =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/build.svg";
const ACCELERATE =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/accelerate.svg";
// const ADD_LIST =
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/add_list.svg";
// const FORM =
//   "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/form.svg";
const ADD_LIST =
  "https://kewordal-images.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/add_list.webp";
const FORM =
  "https://kewordal-images.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/form.webp";
const CREATE_MULTIPLE_CHOICE = "https://youtu.be/lZSmeOxEcE4";

const Section4 = () => {
  // state
  const [isVisible, setIsVisible] = useState(true);
  const [marginTop, setMarginTop] = useState("-1.8rem");
  const [isPlaying, setIsPlaying] = useState(false);

  // hooks
  const theme = useTheme();
  const matches1 = useMediaQuery(theme.breakpoints.between(1300, 1400));
  const matches2 = useMediaQuery(theme.breakpoints.between(1200, 1250));
  const matches3 = useMediaQuery(theme.breakpoints.between(1250, 1300));
  // const matches3 = useMediaQuery(theme.breakpoints.between(1400, 1500));

  useEffect(() => {
    if (matches1) {
      setMarginTop("-2.5rem");
    } else if (matches2) {
      setMarginTop("-3.5rem");
    } else if (matches3) {
      setMarginTop("-2.5rem");
    } else {
      setMarginTop("-1.8rem");
    }
  }, [matches1, matches2, matches3]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setIsVisible(!isVisible);
    }, 5000);

    return () => {
      clearInterval(intervalID);
    };
  }, [isVisible]);

  return (
    <Box className="section-4">
      <div className="top-polygon" />
      <Container sx={{ mt: "-4.5rem" }}>
        <Box
          sx={{
            mb: "2rem",
            display: "flex",
            justifyContent: "center",
            gap: {
              xs: "3rem",
              md: "13rem",
            },
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={JOIN}
              alt="Join"
              width="85px"
              height="85px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Join</h3>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={BUILD}
              alt="Build"
              width="85px"
              height="85px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Build</h3>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={ACCELERATE}
              alt="Accelerate"
              width="85px"
              height="85px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Accelerate</h3>
          </Box>
        </Box>
      </Container>
      <Box sx={{ mb: "-3.2rem" }}>
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                  },
                  alignItems: "center",
                }}
              >
                <Box
                  className="center"
                  onMouseEnter={() => setIsPlaying(true)}
                  onMouseLeave={() => setIsPlaying(false)}
                  sx={{
                    flexGrow: 1,
                    height: "320px",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "8px",
                    mt: marginTop,
                    ml: {
                      xs: 0,
                      sm: "4rem",
                    },
                    maxWidth: {
                      xs: "100%",
                      // sm: "600px",
                      // md: "500px",
                      // lg: "600px",
                    },
                  }}
                >
                  <ReactPlayer
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    width="100%"
                    height="100%"
                    controls={true}
                    url={CREATE_MULTIPLE_CHOICE}
                    // playing={isPlaying}
                    playing
                    light
                    muted
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box className="large-circle" />
                  <AnimatePresence mode="wait" initial={false}>
                    {isVisible && (
                      <motion.img
                        loading="lazy"
                        key="add_list_image"
                        initial={{
                          rotate: -180,
                          scale: 0,
                          opacity: 0.4,
                        }}
                        animate={{
                          rotate: 0,
                          scale: 1.1,
                          opacity: 1,
                        }}
                        exit={{
                          rotate: 180,
                          scale: 0,
                          opacity: 0.4,
                        }}
                        transition={{
                          duration: 0.5,
                        }}
                        src={ADD_LIST}
                        alt="add-list"
                        // height="480px"
                        width="100%"
                        style={{
                          position: "relative",
                          zIndex: 5,
                        }}
                      />
                    )}
                    {!isVisible && (
                      <motion.img
                        loading="lazy"
                        key="form_image"
                        initial={{
                          rotate: -180,
                          scale: 0,
                          opacity: 0.4,
                        }}
                        animate={{
                          rotate: 0,
                          scale: 1.1,
                          opacity: 1,
                        }}
                        exit={{
                          rotate: 180,
                          scale: 0,
                          opacity: 0.4,
                        }}
                        transition={{
                          duration: 0.5,
                        }}
                        src={FORM}
                        alt="add-list"
                        // height="480px"
                        width="100%"
                        style={{
                          position: "relative",
                          zIndex: 5,
                        }}
                      />
                    )}
                  </AnimatePresence>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <div className="bottom-polygon" />
    </Box>
  );
};

export default Section4;
